import Container_InnerShadowAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/InnerShadowAreaSkin/InnerShadowAreaSkin.skin';


const Container_InnerShadowAreaSkin = {
  component: Container_InnerShadowAreaSkinComponent
};


export const components = {
  ['Container_InnerShadowAreaSkin']: Container_InnerShadowAreaSkin
};


// temporary export
export const version = "1.0.0"
